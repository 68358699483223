<template>
  <div class="quota-day">
    <div class="quota-day__title">
      <div class="quota-day__title-day">{{ getDate.day }}</div>
      <span v-if="isMobile" class="quota-day__title-slash">/</span>
      <div class="quota-day__title-weekday">{{ getDate.weekday }}</div>
    </div>

    <div class="quota-day__content">
      <component
        :is="getCurrentComponent(item)"
        v-for="(item, index) in dayData"
        :key="index"
        :quota="item"
        :is-archive="isArchive"
      />
    </div>
  </div>
</template>

<script>
import { GET_DAY, WEEKDAY } from '@/constants/date'
import { GET_IS_MOBILE } from '@/store/actions'
import { getTime } from '@/core'
import { mapGetters } from 'vuex'
import QuotaMultiple from './components/QuotaMultiple'
import QuotaSingle from './components/QuotaSingle'

export default {
  name: 'QuotaDay',
  comments: { QuotaSingle, QuotaMultiple },
  props: {
    dayData: {
      type: Array,
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
    isSingleItem() {
      return Array.isArray(this.dayData[0])
    },
    getDate() {
      const currentDay = this.isSingleItem
        ? this.dayData[0][0]
        : this.dayData[0]

      return {
        day: getTime(currentDay.time_from, GET_DAY),
        weekday: getTime(currentDay.time_from, WEEKDAY),
      }
    },
  },
  methods: {
    getCurrentComponent(item) {
      return item.length > 1 ? QuotaMultiple : QuotaSingle
    },
  },
}
</script>

<style lang="sass">
$width-title: 105px

.quota-day
  padding: 22px 22px 32px
  display: flex
  justify-content: space-between
  align-items: center
  background: #FFFFFF
  border: $border-default
  box-shadow: $box-shadow-default
  border-radius: 8px
  margin-bottom: 11px

  .quota-single + .quota-multiple,
  .quota-multiple + .quota-single
    margin-top: 30px

  &__title
    width: $width-title
    flex: 0 0 $width-title
    margin-right: 10px
    align-self: stretch
    display: flex
    justify-content: flex-start
    align-items: center
    align-content: center
    flex-wrap: wrap

  &__title-day
    width: 100%
    flex: 0 0 100%
    font-weight: 900
    font-size: 21px
    line-height: 25px
    letter-spacing: 0.01em
    color: #4BA7D8

  &__title-weekday
    width: 100%
    flex: 0 0 100%
    font-weight: bold
    font-size: 16px
    line-height: 19px
    letter-spacing: 0.01em
    color: #7E7E7E

  &__content
    flex: 0 0 calc(100% - #{$width-title} - 10px)

  // общие стили для компонентов QuotaSingle и QuotaMultiple
  &__time
    flex: 0 0 238px
    margin-right: 15px
    font-weight: bold
    font-size: 15px
    line-height: 16px
    letter-spacing: 0.5px
    text-transform: uppercase
    color: #7E7E7E
    display: flex
    justify-content: flex-start
    align-items: stretch

  &__time-text
    margin-right: 6px
    display: flex
    justify-content: center
    align-items: center

  &__time-val
    margin-right: 6px
    padding: 7px 8px
    background: #F5F7FA
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    font-size: 14px
    color: #606266
    border: 1px solid #EBEEF5

    &--long
      font-size: 14px
      text-transform: lowercase
      padding: 0
      width: 70px
      margin-left: 5px
      border: none
    &:last-of-type
      margin-right: 0

@media (max-width: 800px)
  .quota-day
    padding: 16px 18px 22px 16px
    flex-direction: column
    align-items: flex-start
    &__title
      flex: auto
      flex-wrap: nowrap
      align-self: auto
      margin: 0 0 6px
      &-slash
        margin: 0 6px
      &-day
        flex: auto
      &-weekday
        flex: auto
    &__content
      flex: auto !important
      width: 100%
</style>
